<template>
    <modal-page-template title="Mot de passe oublié ?"
                         message="Entrez votre adresse mail et nous vous enverrons un lien pour redéfinir votre mot de passe">
        <b-col lg="8" offset-lg="2">
            <b-form @submit.prevent="submit">
                <b-form-group label="Adresse mail">
                    <b-form-input v-model="form.email" type="email" required/>
                </b-form-group>

                <b-btn type="submit" variant="secondary" class="m-3" :disabled="submitted">
                    <font-awesome-icon icon="check"/>
                    Valider
                    <font-awesome-icon icon="sync" spin v-if="loading"/>
                </b-btn>
            </b-form>
        </b-col>
    </modal-page-template>
</template>

<script>
    import {apiPath} from "@/util/http";

    const ModalPageTemplate = () => import('@/components/templates/ModalPageTemplate');

    export default {
        name: 'ForgotPassword',
        components: {ModalPageTemplate},
        data: () => ({
            submitted: false,
            loading: false,
            form: {
                email: null
            }
        }),
        methods: {
            submit() {
                if (!this.submitted) {
                    this.submitted = true;
                    this.loading = true;
                    this.axios.post(apiPath('trigger_password_reset'), this.form)
                        .then(() => this.$toaster.success('Un mail vous a été envoyé, pensez à vérifier vos spams'))
                        .catch(() => this.$toaster.error('Erreur inconnue'))
                        .finally(() => {
                            this.loading = false;
                            setTimeout(() => this.submitted = false, 30000); // Disable button for 30s, to avoid billions of tokens in the database
                        })
                }
            }
        }
    };
</script>
